import request from '../utils/request.js'

//获取服务器列表
export function list(data) {
	return request({
		url: `/server/list`,
		method: 'get',
		params:data
	})
}
//添加服务器
export function add(data) {
	return request({
		url: `/server/add`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//编辑服务器
export function edit(data) {
	return request({
		url: `/server/edit`,
		method: 'put',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//删除服务器
export function deletes(data) {
	return request({
		url: `/server/delete/server`,
		method: 'DELETE',
		params:data
	})
}
//获取服务器下在线设备列表
export function lists(data) {
	return request({
		url: `/server/online`,
		method: 'get',
		params:data
	})
}
//获取服务器日志列表
export function journallist(data) {
	return request({
		url: `/server/serverLog/list`,
		method: 'get',
		params:data
	})
}
//获取服务器日志详情
export function journaldetails(data) {
	return request({
		url: `/server/deviceLog/details`,
		method: 'get',
		params:data
	})
}

//获取服务器详情
export function serverDetail(mid) {
	return request({
		url: `/server/serverDetail/${mid}`,
		method: 'get',
	})
}
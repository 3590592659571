<template>
  <div class="bgcard">
    <div class="headerMsg">
      <div class="addbtn">
        <button @click="newly">+ {{ $t("lang.AddServer") }}</button>
      </div>
      <div class="searchlist">
        <div class="select_b" style="margin-right: 0.2rem">
          <span style="color: #bbbbbb">{{ $t("lang.type") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.5rem"
            v-model="value"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="screen"
          >
            <el-option :label="$t('lang.whole')" :value="''"></el-option>
            <el-option :label="$t('lang.Cloudserver')" :value="1"></el-option>
            <el-option :label="$t('lang.Localserver')" :value="2"></el-option>
          </el-select>
        </div>
        <div class="select_b" style="margin-right: 0.2rem">
          <span style="color: #bbbbbb">{{ $t("lang.ResourceStatus") }}：</span>
          <el-select
            style="height: 0.4rem"
            v-model="value1"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="screens"
          >
            <el-option :label="$t('lang.whole')" value=""></el-option>
            <el-option :label="$t('lang.normal')" :value="0"></el-option>
            <el-option :label="$t('lang.Generalalarm')" :value="1"></el-option>
            <el-option :label="$t('lang.majoralarm')" :value="2"></el-option>
            <el-option
              :label="$t('lang.Emergencyalarm')"
              :value="3"
            ></el-option>
          </el-select>
        </div>
        <div class="query">
          <div class="inputname">
            <input v-model="input" :placeholder="$t('lang.servernameorsn')" />
          </div>
          <div class="searchbtn">
            <button @click="sear">
              <p>{{ $t("lang.search") }}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column prop="name" :label="$t('lang.server')" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="cpu" label="cpu" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="memUsed" :label="$t('lang.neicunad')" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="memTotal" :label="$t('lang.neicunac')" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="storageUsed" :label="$t('lang.chipanac')" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="storageTotal" :label="$t('lang.chipanad')" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="flowSend" :label="$t('lang.shangxiang')" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <el-table-column prop="flowRecv" :label="$t('lang.xiaxing')" align="center" show-overflow-tooltip width="150">
        </el-table-column>
        <!-- <el-table-column :label="$t('lang.type')" align="center" width="150">
          <template #default="scope">
            <span v-if="scope.row.modelType == 1">{{
              $t("lang.Cloudserver")
            }}</span>
            <span v-if="scope.row.modelType == 2">{{
              $t("lang.Localserver")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="location" :label="$t('lang.Location')" align="center" show-overflow-tooltip width="150">
        </el-table-column> -->
        <el-table-column prop="network" :label="$t('lang.networkconfiguration')" align="center" width="250">
        </el-table-column>
        <el-table-column :label="$t('lang.RunningState')" align="center" width="200">
          <template #default="scope">
            <span v-if="scope.row.sysStat == 0">{{ $t("lang.Deleted") }}</span>
            <span v-if="scope.row.sysStat == 1">{{ $t("lang.normal") }}</span>
            <span v-if="scope.row.sysStat == 2">{{ $t("lang.fault") }}</span>
            <span v-if="scope.row.sysStat == 3">{{ $t("lang.maintain") }}</span>
            <span v-if="scope.row.sysStat == 4">{{ $t("lang.offline") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.BusinessState')" align="center" width="200">
          <template #default="scope">
            <span v-if="scope.row.stat == 0">{{ $t("lang.normal") }}</span>
            <span v-else>{{ $t("lang.abnormal") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.ResourceStatus')" align="center" width="150">
          <template #default="scope">
            <span v-if="scope.row.warningClass == 0">{{
              $t("lang.normal")
            }}</span>
            <span v-if="scope.row.warningClass == 1">{{
              $t("lang.Generalalarm")
            }}</span>
            <span v-if="scope.row.warningClass == 2">{{
              $t("lang.majoralarm")
            }}</span>
            <span v-if="scope.row.warningClass == 3">{{
              $t("lang.Emergencyalarm")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.OnlineTerminal')" align="center" width="150">
          <template #default="scope">
            <a href="javascript:;" style="text-decoration: none;">
							<span @click="terminal(scope.row)" style="color: darkorange;">{{scope.row.onlineDevs}}</span>
						</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="leasetime"
          :label="$t('lang.Leasetime')"
          width="240"
          align="center"
        >
          <template #default="scope">
            <span v-if="scope.row.startTime || scope.row.endTime"
              >{{ scope.row.startTime | formatTimerM }}~{{
                scope.row.endTime | formatTimerM
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          width="250"
          align="center"
        >
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip
                effect="dark"
                :content='$t("lang.edit")'
                placement="left"
                v-if="scope.row.sysStat == 1"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="closemsg(scope.row, 1)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content='$t("lang.edit")'
                placement="left"
                v-else
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                  @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                  @click="closemsg(scope.row, 2)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag2 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag2 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.details")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_detail"
                  @mouseenter="enterOne(3, scope.row.id, scope.row.flag3)"
                  @mouseleave="leaveOne(3, scope.row.id, scope.row.flag3)"
                  @click="detailsmsg(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag3 === 0"
                    src="@/assets/imgs/bottom/xiangqing.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag3 === 1"
                    src="@/assets/imgs/bottom/xiangqing2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.deletes")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_delete"
                  @mouseenter="enterOne(4, scope.row.id, scope.row.flag4)"
                  @mouseleave="leaveOne(4, scope.row.id, scope.row.flag4)"
                  @click="deletemsg(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag4 === 0"
                    src="@/assets/imgs/bottom/shanchu.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag4 === 1"
                    src="@/assets/imgs/bottom/shanchu2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center" v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          popper-class="paging"
          background
          layout="prev, pager, next"
          :current-page="Number(parsm.page)"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 模态框 -->
    <el-dialog
      :title="$t('lang.EditServer')"
      :visible.sync="showSee"
      :show-close="false"
      width="25%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="setmsgs">
        <el-form-item :label="$t('lang.ServerName')" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.Location')" prop="location">
          <el-input v-model="ruleForm.location"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.IpAddress')" prop="ip">
          <el-input v-model="ruleForm.ip"></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.port')" prop="port">
          <el-input v-model="ruleForm.port"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="submitForm('ruleForm')">
            <p>{{ $t("lang.submit") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
    <!-- 删除模态框 -->
    <el-dialog
      :title="$t('lang.Deleteserver')"
      :visible.sync="showSees"
      :show-close="false"
      width="25%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div class="delete">
        <span>{{ $t("lang.Areyousureyouwanttodeletethisserver") }}？</span>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSees = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine">
            <p>{{ $t("lang.determine") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as server from "@/api/server.js";
import * as RegExp from "@/ui-utils/RegExp.js";
export default {
  data() {
    var ip = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.PleaseIPaddress"));
      } else if (!RegExp.ip.test(value)) {
        callback(new Error(this.$t("lang.Pleaseenterthecorrect")));
      } else {
        callback();
      }
    };
    var accessIP = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseentertheaccess"));
      } else if (!RegExp.ip.test(value)) {
        callback(new Error(this.$t("lang.Pleaseenterthecorrectaccess")));
      } else {
        callback();
      }
    };
    var accessPort = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseentertheport"));
      } else if (!RegExp.port.test(value)) {
        callback(new Error(this.$t("lang.Pleaseenterthecorrectport")));
      } else {
        callback();
      }
    };
    return {
      seenOnetext: this.$t("lang.binding"),
      seenTwotext: this.$t("lang.Unbundling"),
      seenOne: true,
      seenTwo: true,
      seenThree: true,
      seenFree: true,
      seenFive: true,

      input: "",
      value: "",
      value1: "",
      tableData: [],
      parsm: {
        page: 1,
        size: 10,
        modelType: "",
        nameOrSn: "",
        warningClass: "",
      },
      showSee: false,
      ruleForm: {
        //表单数据合集
        name: "",
        location: "",
        ip: "",
        //accessIP:'',
        port: "",
        status: "",
        modelType: "",
        type: "",
      },
      rules: {
        //验证规则
        name: [
          {
            required: true,
            message: this.$t("lang.Pleaseentertheserver"),
            trigger: "blur",
          },
        ],
        location: [
          {
            required: true,
            message: this.$t("lang.Pleaseentertheregion"),
            trigger: "blur",
          },
        ],
        ip: [
          {
            required: true,
            validator: ip,
            trigger: "blur",
          },
        ],
        /* accessIP:[{
						required: true,
						validator: ip,
						trigger: 'blur'
					}], */
        port: [
          {
            required: true,
            validator: accessPort,
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectrunning"),
            trigger: "change",
          },
        ],
        modelType: [
          {
            required: true,
            message: this.$t("lang.Pleaseselecttype"),
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectoperation"),
            trigger: "change",
          },
        ],
      },
      showSees: false,
      deletedata: {},
    };
  },
  filters: {
    formatTimerM: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.parsm.page = this.$route.query.page;
    }
    this.getList();
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1 ) {
          if(ids == this.tableData.list[i].id && flag === 0){
            this.tableData.list[i].flag1 = 1
          }
        }
         if (index === 2 ) {
          if(ids == this.tableData.list[i].id && flag === 0){
            this.tableData.list[i].flag2 = 1
          }
        }
         if (index === 3 ) {
          if(ids == this.tableData.list[i].id && flag === 0){
            this.tableData.list[i].flag3 = 1
          }
        }
         if (index === 4 ) {
          if(ids == this.tableData.list[i].id && flag === 0){
            this.tableData.list[i].flag4 = 1
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
       for (let i in this.tableData.list) {
        if (index === 1 ) {
          if(ids == this.tableData.list[i].id && flag === 1){
            this.tableData.list[i].flag1 = 0
          }
        }
         if (index === 2 ) {
          if(ids == this.tableData.list[i].id && flag === 1){
            this.tableData.list[i].flag2 = 0
          }
        }
         if (index === 3 ) {
          if(ids == this.tableData.list[i].id && flag === 1){
            this.tableData.list[i].flag3 = 0
          }
        }
         if (index === 4 ) {
          if(ids == this.tableData.list[i].id && flag === 1){
            this.tableData.list[i].flag4 = 0
          }
        }
      }
    },

    //获取服务器列表
    async getList() {
      let res = await server.list(this.parsm);
      if (res.data) {
        res.data.list.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
          item.flag3 = 0;
          item.flag4 = 0;
        });
        this.tableData = res.data;
      } else {
        this.tableData = [];
      }
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //添加服务器
    newly() {
      this.$router.push({
        path: "newlyserver",
      });
    },
    //类型筛选
    screen(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.modelType = e;
      this.getList();
    },
    //资源状态筛选
    screens(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.warningClass = e;
      this.getList();
    },
    //名称、sn筛选
    sear() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.nameOrSn = this.input;
      this.getList();
    },
    //服务器详情
    detailsmsg(row) {
      this.$router.push({
        path: "serverdetails",
        query: { data: JSON.stringify(row), page: this.parsm.page },
      });
    },
    //编辑服务器
    closemsg(row, index) {
      console.log(row);
      if (index === 1) {
        this.ruleForm = JSON.parse(JSON.stringify(row));
        /* this.ruleForm.mid=row.mid
				this.ruleForm.name=row.name
				this.ruleForm.location=row.location
				this.ruleForm.ip=row.ip
				this.ruleForm.accessIP=row.accessIP
				this.ruleForm.port=row.port
				this.ruleForm.status=row.status */

        this.showSee = true;
        this.$nextTick(() => {
          this.$refs["ruleForm"].clearValidate();
        });
      } else {
        this.$router.push({
          path: "newlyserver",
          query: { data: JSON.stringify(row), page: this.parsm.page },
        });
      }
    },
    //打开删除模态框
    deletemsg(row) {
      this.showSees = true;
      console.log(row);
      let adminemail = sessionStorage.getItem("user");
      if (adminemail) {
        this.deletedata.adminEmail = JSON.parse(adminemail).email;
      }
      this.deletedata.mid = row.mid;
      this.deletedata.name = row.name;
    },
    //删除服务器
    determine() {
      server.deletes(this.deletedata).then((res) => {
        this.$message.success(this.$t("lang.Operations"));
        this.isLastPage();
        this.getList();
      });
      this.showSees = false;
    },
    //在线终端数
    terminal(row) {
      this.$router.push({
        path: "terminallist",
        query: { mid: row.mid },
      });
    },
    //保存编辑内容
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let adminemail = sessionStorage.getItem("user");
          if (adminemail) {
            this.ruleForm.adminEmail = JSON.parse(adminemail).email;
          }
          server.edit(this.ruleForm).then((res) => {
            this.$message.success(this.$t("lang.Submitted"));
            this.showSee = false;
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
    //判断当前页是否是最后一页
    isLastPage() {
      let lastPage = Math.ceil((this.tableData.total - 1) / this.parsm.size);
      //console.log(lastPage)
      if (this.parsm.page == lastPage || this.parsm.page > lastPage) {
        this.parsm.page = lastPage;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: space-between;

  .addbtn {
    button {
      height: 0.5rem;
      border: none;
      outline-style: none;
      padding: 0px 0.12rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

/* 模态框 */
/deep/.setmsgs {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .el-input,
  .el-select {
    width: 2rem;
  }
	.el-form-item__label{
		display: inline-block;
		width: 1.4rem;
	}
	.el-form-item__content{
		margin-left: 0 !important;
	}
	.el-form-item{
		display: flex;
		align-items: center;
	}
}
.delete {
  height: 0.5rem;
  text-align: center;
  font-size: 0.16rem;
  color: #333333;
}
.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 1px solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
</style>
